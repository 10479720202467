import * as React from "react"
import Banner from '../components/ui/banner'
import Drops from '../components/ui/drops'
import Gallery from '../components/ui/gallery'
import Traits from '../components/ui/traits'
import "../styles/global.css"

// styles
const pageStyles = {
  padding: 25,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  backgroundColor: '#c0c0c0',
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const infoBlock = {
  maxWidth: 608,
  marginBottom: 20,
}
const claim={
  padding: 16,
  marginBottom: 20,
}
const content = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column',
}
const logoStyles = {
  width: '20%',
  height: '20%',
  marginRight: '16px',
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <link rel="stylesheet" href="https://unpkg.com/98.css" />
      <title>Webbies</title>
      <Banner />
      <div style={content}>
        <a href="https://opensea.io/collection/webbies" target="_blank"><button style={claim}>Claim your Webbie</button></a>
        <div class="window" style={infoBlock}>
        <div class="title-bar">
          <div class="title-bar-text">What's a Webbie?</div>
          <div class="title-bar-controls">
            <button aria-label="Minimize"></button>
            <button aria-label="Maximize"></button>
            <button aria-label="Close"></button>
          </div>
        </div>
        <div class="window-body">
          <p>A website's unique footprint.  Programmatically generated, guided by links starting at the root url, no two Webbies will be the same. </p>
        </div>
        <ul class="tree-view">
          <li>Drops</li>
          <Drops />
        </ul>
      </div>
      <div style={infoBlock}>
        <h2>Traits</h2>
        <Traits />
      </div>
      <div style={infoBlock}>
        <h2>Gallery</h2>
        <div className="galleryContent">
          <Gallery />
        </div>
      </div>
      </div>
    </main>
  )
}

export default IndexPage
