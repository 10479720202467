import * as React from "react"

const drops = [
  {
    name: 'Crypto Series',
    webbies: [
      {
        name: 'bitcoin.org (1 of 1)',
      },
      {
        name: 'ethereum.org (1 of 1)',
      },
      {
        name: 'uniswap.org (1 of 1)',
      },
      {
        name: 'storj.io (1 of 1)',
      },
      {
        name: 'dogecoin.org (1 of 1)',
      },
      {
        name: 'tether.to (1 of 1)',
      },
      {
        name: 'stellar.org (1 of 1)',
      },
      {
        name: 'nem.io (1 of 1)',
      },
      {
        name: 'neo.org (1 of 1)',
      },
      {
        name: 'tron.org (1 of 1)',
      },
      {
        name: 'basicattentiontoken.org (1 of 1)',
      },
      {
        name: 'polkadot.network (1 of 1)',
      },
      {
        name: 'ripple.com (1 of 1)',
      },
    ]
  }
]

// markup
function Drops() {
  return (
    drops.map(d => {
      return (
        <li>
          <details>
            <summary>{d.name}</summary>
            <ul>
              {d.webbies.map(w => {
                return (
                  <li>{w.name}</li>
                )
              })}
            </ul>
          </details>
        </li>
      )
    })
  )
}

export default Drops
