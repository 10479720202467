import * as React from "react"
import Bitcoin from "../../../assets/bitcoin.org.interations-3.background-white.node-half-rhomboid.shape-uncontained.nodesize-4.connectorsize-1.svg"
import Ethereum from "../../../assets/etherium.org.interations-2.background-white.node-half-obcordat.shape-uncontained.nodesize-4.connectorsize-1.svg"
import Uniswap from "../../../assets/uniswap.org.interations-1.background-black.node-deltoid.shape-palm.nodesize-4.connectorsize-1.svg"
import Storj from "../../../assets/storj.io.interations-2.background-black.node-lanceolate.shape-rising-sun.nodesize-4.connectorsize-1.svg"
import Dogecoin from "../../../assets/dogecoin.org.interations-1.background-white.node-elliptic.shape-palm.nodesize-4.connectorsize-1.svg"
import Tether from "../../../assets/tether.to.interations-2.background-white.node-lanceolate.shape-risingsun.nodesize-4.connectorsize-1.svg"
import Stellar from "../../../assets/stellar.org.interations-2.background-violet.node-cordate.shape-root.nodesize-4.connectorsize-1.svg"
import Nem from "../../../assets/nem.io.interations-2.background-black.node-cordate.shape-root.nodesize-4.connectorsize-1.svg"
import Neo from "../../../assets/neo.org.interations-1.background-black.node-lanceolate.shape-palm.nodesize-4.connectorsize-1.svg"
import Tron from "../../../assets/tron.network.interations-1.background-white.node-rhomboid.shape-rising-sun.nodesize-4.connectorsize-1.svg"
import Basicattentiontoken from "../../../assets/basicattentiontoken.org.interations-1.background-black.node-elliptic.shape-palm.nodesize-4.connectorsize-1.svg"
import Polkadot from "../../../assets/polkadot.network.interations-1.background-grey.node-cordate.shape-pie.nodesize-4.connectorsize-1.svg"
import Ripple from "../../../assets/ripple.com.interations-2.background-grey.node-elliptic.shape-rising-sun.nodesize-4.connectorsize-1.svg"

const webbies = [
  Bitcoin,
  Ethereum,
  Uniswap,
  Storj,
  Dogecoin,
  Tether,
  Stellar,
  Nem,
  Neo,
  Tron,
  Basicattentiontoken,
  Polkadot,
  Ripple,
]

// markup
function Gallery() {
  return (
    webbies.map(Component => {
      return (
        <div className="galleryImage">
          <Component />
        </div>
      )
    })
  )
}

export default Gallery
