import * as React from "react"
import logo from "./logo2.png"

// styles
const pageStyles = {
  paddingBottom: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const logoStyles = {
  width: '20%',
  height: '20%',
  marginRight: '16px',
}

const bannerText = {
  fontSize: 'calc(5em + 1vw)',
}

// markup
function Banner() {
  return (
    <div style={pageStyles}>
      <img
        style={logoStyles}
        alt="Webbies"
        src={logo}
      />
      <h1 style={bannerText}>Webbies</h1>
    </div>
  )
}

export default Banner
