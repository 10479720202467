import * as React from "react"

const traits = [
  {
    name: 'Host',
    description: 'The source of the Webbie.  A website\'s URL.',
  },
  {
    name: 'Depth',
    description: 'How far out we follow the links when generating the Webbie. The deeper we go, the bigger the Webbie. Always go von deeper.',
  },
  {
    name: 'Background',
    description: 'Background color of a Webbie.',
  },
  {
    name: 'Arrangement',
    description: 'Each thread in a webbie starts and ends at a node. The arrangement is derived from the relationships between the nodes.',
  },
  {
    name: 'Shape',
    description: 'Webbies often form similar shapes and patterns.',
  },
]

// markup
function Traits() {
  return (
    traits.map(t => {
      return (
        <div className="trait">
          <h4 className="trait-name">{t.name}</h4>
          <blockquote className="trait-description">{t.description}</blockquote>
        </div>
      )
    })
  )
}

export default Traits
